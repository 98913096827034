<template>
  <div class="label-circle" v-bind:class="[color ? 'label-circle--' + color : '']">
    <template v-if="value.length === 1">
      <span>
        {{value}}
      </span>
    </template>
    <template v-else>
      {{value}}
    </template>

  </div>
</template>

<script>
  export default {
    name: "LabelCircle",

    props: [
      'color',
      'value'
    ],
  }
</script>

<style lang="scss">
  @import "../../../scss/colors";



</style>
